:root {
  --blue: #012979;
  --lightGrey: #f0f0f0;
  --orange: #f49805;
  --darkGrey: #313334;
  --green: #57df57;

  --mainTextColor: var(--blue);
  --secondaryColor: var(--green);
  --textColor: var(--darkGray);
  --line: var(--lightGrey);
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font: 500 16px 'Open Sans', sans-serif;
  outline-offset: 6px;
  outline-color: black;
}

html,
body,
#root,
.App {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  max-width: none;
}
